<template>
  <Layout>
    <div class="card px-4 py-5">
      <div v-if="preparingForm" class="text-center">Preparing Form..</div>
      <div v-else>
        <div
          class="text-center"
          v-if="userType === 'admin' && availableVendor.length === 0"
        >
          Sorry currently vendors are not available!
        </div>
        <div v-else>
          <FormulateForm
            class="add-program-form"
            @submit="addProgram"
            v-model="formValues"
          >
            <FormulateInput
              name="name"
              type="text"
              label="Program Name"
              placeholder="Program name"
              validation="required"
            />
            <div class="row">
              <div class="col-md-6" v-if="userType === 'admin'">
                <FormulateInput
                  name="vendor_id"
                  :options="availableVendor"
                  type="select"
                  label="Vendor"
                  placeholder="Choose vendor"
                  validation="required"
                  @change="filterTribes"
                />
              </div>
              <div class="col-md-6">
                <FormulateInput
                  name="tribe_id"
                  :options="availableTribe"
                  type="select"
                  label="Tribe"
                  placeholder="Choose The tribe"
                  validation="required"
                />
              </div>
            </div>

            <div class="row">
              <div class="col-sm-12 col-md-6">
                <FormulateInput
                  name="description"
                  type="textarea"
                  label="Description"
                  placeholder="Description"
                  validation="required"
                />
              </div>
              <div class="col-sm-12 col-md-6">
                <FormulateInput
                  name="banner"
                  type="image"
                  label="banner"
                  :uploader="uploadFile"
                  placeholder="Banner"
                  validation="required"
                />
              </div>
            </div>

            <div class="row">
              <div class="col-sm-12 col-md-6">
                <div class="row">
                  <div class="col-6">
                    <FormulateInput
                      name="min_people"
                      type="number"
                      label="Min people"
                      placeholder="Min people"
                      validation="required"
                      min="1"
                    />
                    <p class="text-danger" id="program-min-error">
                      Minimum people must be less than maximum people
                    </p>
                  </div>
                  <div class="col-6">
                    <FormulateInput
                      name="max_people"
                      type="number"
                      label="Max people"
                      placeholder="Max people"
                      validation="required"
                      max="100"
                      min="1"
                    />
                  </div>
                </div>
              </div>
              <div class="col-sm-6 col-md-3" v-if="userType === 'admin'">
                <FormulateInput
                  name="vat"
                  label="VAT (%)"
                  placeholder="Vat"
                  max="100.0"
                  min="0.0"
                  value="0.0"
                  type="text"
                  pattern="[+-]?((\d+\.?\d*)|(\.\d+))"
                  inputmode="numeric"
                />
              </div>
            </div>

            <FormulateInput
              name="is_package"
              type="checkbox"
              :checked="false"
              class="package-included mt-5"
              label="Package included"
              @change="packageIncludedAction"
            />
            <FormulateInput
              type="group"
              class="packages"
              :repeatable="false"
              label="Packages"
              name="packages"
              :minimum="minimum_packages"
              :limit="minimum_packages"
              #default="{ index }"
            >
              <div class="row" v-if="!(min_people - 1 > index)">
                <div class="col-md-3">
                  <FormulateInput
                    name="no_of_people"
                    type="number"
                    label="No. people"
                    placeholder="Number of people"
                    validation="required"
                    :value="index + 1"
                    :disabled="is_package"
                  />
                </div>

                <div class="col-md-3">
                  <FormulateInput
                    name="cost"
                    label="cost"
                    placeholder="Cost"
                    validation="required"
                    value="0.0"
                    type="text"
                    pattern="[+-]?((\d+\.?\d*)|(\.\d+))"
                    inputmode="numeric"
                  />
                </div>

                <div class="col-md-3">
                  <FormulateInput
                    name="markup_percentage"
                    label="Markup Percentage"
                    max="100"
                    min="0.0"
                    value="0.0"
                    type="text"
                    pattern="[+-]?((\d+\.?\d*)|(\.\d+))"
                    inputmode="numeric"
                    v-if="userType === 'admin'"
                    validation="required"
                    placeholder="Markup percentage"
                  />
                </div>
                <div class="col-md-3">
                  <FormulateInput
                    name="total_price"
                    value="0.0"
                    label="Total Price"
                    placeholder="Total price"
                    validation="required"
                    type="text"
                    pattern="[+-]?((\d+\.?\d*)|(\.\d+))"
                    inputmode="numeric"
                    v-if="userType === 'admin'"
                    disabled
                  />
                </div>
              </div>
            </FormulateInput>
            <div
              class="d-flex justify-content-end"
              style="width: 100%"
              v-if="userType === 'admin'"
            >
              <button type="button" @click="calculteTotal" class="date-gen-btn">
                Calculte Total
              </button>
            </div>

            <div>
              <FormulateInput
                type="group"
                class="policies my-5"
                label="Policy"
                name="policy"
              >
                <div class="row">
                  <div class="col-sm-12 col-md-6">
                    <FormulateInput
                      name="covid_requirements"
                      type="textarea"
                      label="Covid Requirements"
                      placeholder="Covid Requirements"
                      validation="required"
                    />
                  </div>
                  <div class="col-sm-12 col-md-6">
                    <FormulateInput
                      name="cancellation_policy"
                      type="textarea"
                      label="Cancellation Policy"
                      placeholder="Cancellation policy"
                      validation="required"
                    />
                  </div>
                  <div class="col-sm-12 col-md-6">
                    <FormulateInput
                      name="included"
                      type="textarea"
                      label="Included"
                      placeholder="Included"
                      validation="required"
                    />
                  </div>
                  <div class="col-sm-12 col-md-6">
                    <FormulateInput
                      name="excluded"
                      type="textarea"
                      label="Excluded"
                      placeholder="Excluded"
                      validation="required"
                    />
                  </div>
                </div>
              </FormulateInput>
            </div>
            <FormulateInput
              type="group"
              class="details mb-5"
              :repeatable="true"
              label="Itinerary"
              name="details"
              #default="{ index }"
            >
              <FormulateInput
                name="title"
                type="text"
                :label="`Day ${index + 1}`"
                placeholder="Title"
                validation="required"
              />

              <div class="row">
                <div class="col-sm-12 col-md-6">
                  <FormulateInput
                    name="description"
                    type="textarea"
                    label="Description"
                    placeholder="Description"
                    validation="required"
                  />
                </div>
                <div class="col-sm-12 col-md-6">
                  <FormulateInput
                    name="image"
                    type="image"
                    label="Image"
                    :uploader="uploadFile"
                    placeholder="Image"
                    validation="required"
                  />
                </div>
              </div>
            </FormulateInput>

            <div class="d-flex justify-content-end" style="width: 100%">
              <button
                type="button"
                @click="$bvModal.show('date-gen')"
                class="date-gen-btn"
              >
                Generate Date
              </button>
              <dateGen
                v-on:addDateGenerated="addDateGenerated"
                v-on:removeDates="removeDates"
              />
            </div>
            <FormulateInput
              type="group"
              :repeatable="true"
              label="Available date"
              name="available_dates"
              class="program-date"
              style="margin-top: -38px"
            >
              <FormulateInput
                name="date"
                type="date"
                label="Date"
                :value="moment().format('YYYY-MM-DD')"
                placeholder="Available dates"
                validation="required"
              />
            </FormulateInput>
            <FormulateInput
              name="all_dates_available"
              type="checkbox"
              :value="false"
              class="all-date-available"
              label="All dates available"
            />

            <FormulateInput
              class="float-right"
              type="submit"
              :label="addingProgram ? 'Adding Program...' : 'Add Program'"
            />
          </FormulateForm>
        </div>
      </div>
    </div>
  </Layout>
</template>

<script>
import Layout from "@/views/layouts/main";
import { program } from "@/config/api/programs";
import dateGen from "@/views/pages/programs/date-gen.vue";
export default {
  data() {
    return {
      availableVendor: [],
      availableTribe: [],
      formValues: {},
      preparingForm: false,
      addingProgram: false,
      is_package: false,
      minimum_packages: 1,
      min_people: 1,
    };
  },
  created() {
    this.getAllVendorAvailableForProgram();
  },
  methods: {
    addProgram(data) {
      if(data.min_people > data.max_people){
        return
      }
      if (this.userType === "admin") {
        this.calculteTotal();
      }

      this.addingProgram = true;

      const api =
        this.userType === "admin"
          ? program.createProgramAdmin
          : program.createProgram;

      api.data = this.formatData(data);
      console.log(api.data);
      this.generateAPI(api)
        .then(() => {
          this.$bvToast.toast("Program added successfully", {
            title: "Program",
            variant: "success",
            solid: true,
          });
          this.$router.push({ name: "viewPrograms" });
        })
        .catch((err) => {
          this.$bvToast.toast("Program is not added!", {
            title: "Program",
            variant: "danger",
            solid: true,
          });
          console.log(err.data);
        })
        .finally(() => {
          this.addingProgram = false;
        });
    },
    formatData(data) {
      data.banner = data.banner.length ? data.banner[0].link : "";
      data.all_dates_available = data.all_dates_available || false;
      data.more_details = {
        min_people: data.min_people,
        max_people: data.max_people,
        included: data.policy[0].included,
        excluded: data.policy[0].excluded,
      };

      data.packages = data.packages.splice(
        this.min_people - 1,
        data.max_people
      );

      data.available_dates = data.available_dates.map((item) => {
        return item.date;
      });
      data.cancellation_policy = data.policy[0].cancellation_policy;
      data.covid_requirements = data.policy[0].covid_requirements || "";
      delete data.policy;
      data.is_package = data.is_package ? true : false;
      data.details = data.details.map((item) => {
        return {
          title: item.title,
          description: item.description,
          image: item.image.results.length ? item.image.results[0].link : "",
        };
      });
      return data;
    },
    async uploadFile(file, progress) {
      const form = new FormData();
      form.append("file", file);
      form.append("type", this.userType === "admin" ? "admin" : "vendor");
      const api = program.uploadMultimedia;
      api.data = form;
      const result = await this.fileUploadAPI(api, progress).then((res) => {
        return res.data;
      });
      return result;
    },
    minPeopleErrorShow() {
      document.getElementById("program-min-error").style.display = "block";
    },
    minPeopleErrorHide() {
      document.getElementById("program-min-error").style.display = "none";
    },
    getAllVendorAvailableForProgram() {
      this.preparingForm = true;

      const api = program.getAvailableVendor;
      this.generateAPI(api)
        .then((res) => {
          this.availableVendor = res.data.vendors.map((item) => {
            return {
              label: item.company_name,
              value: item._id,
              tribes: item.assigned_tribe,
            };
          });

          if (this.userType === "vendor") {
            this.formValues.vendor_id = this.user._id;
            this.filterTribes();
          }
        })
        .catch((err) => {
          console.log(err.response.data);
        })
        .finally(() => {
          this.preparingForm = false;
        });
    },
    addDateGenerated(data) {
      this.$bvModal.hide("date-gen");

      const date_already_available = this.formValues.available_dates.map(
        (item) => item.date
      );

      for (let i = 0; i < data.length; i++) {
        if (!date_already_available.includes(data[i])) {
          this.formValues.available_dates.push({
            date: data[i],
            _id: i,
          });
        }
      }
    },
    removeDates(data) {
      this.$bvModal.hide("date-gen");

      const date_already_available = this.formValues.available_dates.map(
        (item) => item.date
      );

      for (let i = 0; i < data.length; i++) {
        if (date_already_available.includes(data[i])) {
          this.formValues.available_dates.pop(data[i]);
        }
      }
    },

    calculteTotal() {
      for (let i = 0; i < this.formValues.packages.length; i++) {
        this.formValues.packages[i].total_price = this.total(
          this.formValues.packages[i]
        ).toFixed(2);
      }
    },
    total(item) {
      const tax = this.formValues.vat || 0;
      const total_price = parseFloat(item.cost) * parseFloat(item.no_of_people);

      return (
        parseFloat(total_price) +
        (total_price * item.markup_percentage) / 100 +
        (((total_price * item.markup_percentage) / 100) * tax) / 100
      );
    },
    filterTribes() {
      console.log("Loading available tribes");
      this.availableTribe = this.availableVendor
        .find((item) => item.value == this.formValues.vendor_id)
        .tribes.map((item) => {
          return {
            label: item.name,
            value: item._id,
          };
        });
    },
    packageIncludedAction(val) {
      this.is_package = val.target.checked;
      if (this.is_package) {
        if (this.formValues.max_people <= 50) {
          this.min_people = this.formValues.min_people;
          this.minimum_packages = this.formValues.max_people || 0;
          console.log(this.minimum_packages);
        } else {
          this.$bvToast.toast("Max people must beteen 1-50", {
            title: "Max People",
            variant: "danger",
            solid: true,
          });
        }
      } else {
        this.minimum_packages = 1;
        this.formValues.packages = [this.formValues.packages[0]];
      }
    },
  },
  components: {
    Layout,
    dateGen,
  },
  computed: {
    userType() {
      return this.$store.getters["auth/userType"];
    },
    user() {
      return this.$store.getters["auth/user"];
    },
  },
  watch: {
    formValues(val) {
      if (val.min_people > val.max_people) {
        this.minPeopleErrorShow();
      } else {
        this.minPeopleErrorHide();
      }
      if (
        (val.max_people && val.max_people !== this.minimum_packages) ||
        (val.min_people && val.min_people !== this.min_people)
      ) {
        if (val.is_package && val.max_people) {
          if (val.max_people <= 50) {
            this.minimum_packages = val.max_people;
            this.min_people = val.min_people;
            this.formValues.packages = this.formValues.packages.slice(
              0,
              this.minimum_packages
            );
          } else {
            this.$bvToast.toast("Max people must between 1-50", {
              title: "Max People",
              variant: "danger",
              solid: true,
            });
          }
        }
      }
    },
  },
};
</script>

<style>
.add-program-form {
  padding: 32px;
}
.program-date .formulate-input-grouping {
  display: flex;
  flex-wrap: wrap;
}

.add-program-form
  .formulate-input[data-classification="group"]
  [data-is-repeatable]
  .formulate-input-group-repeatable {
  border: unset;
}
.add-program-form
  .formulate-input[data-classification="group"]
  [data-is-repeatable]
  .formulate-input-group-repeatable-remove {
  top: 62px;
}
.add-program-form .policies .formulate-input-group {
  padding: 1em;
  border: 1px solid #efefef;
  border-radius: 0.3em;
}
.formulate-input-element .formulate-input-element--button {
  text-align: end;
}
.date-gen-btn {
  background-color: #fff;
  border: 1px solid black;
  border-radius: 4px;
  padding: 4px 8px;
  color: #fa7da9;
  font-size: 12px;
  opacity: 0.7;
}
.date-gen-btn:hover {
  opacity: 1;
}
.all-date-available .formulate-input-element--checkbox {
  margin-top: 8px !important;
}
.package-included {
  margin-bottom: 4px;
}
.package-included .formulate-input-element--checkbox {
  margin-top: 8px !important;
}

</style>
